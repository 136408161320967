<template>
    <div id="ourwork" class = "py-5">
        <!-- <header>
            <img src="../assets/imgs/wave.svg" alt="">
        </header> -->
        <section class = "pt-4">
            <div class="title-section py-5 text-center">
                <h2 v-text = "pageContent.work_title[getLang]"></h2>
                <span class = "underline d-block mx-auto"></span>
            </div>
            <b-container>    
                <div class="work-content px-5">
                    <work-card v-for = "project in projects" :key="project.id" :work = "project"></work-card>
                </div>
            </b-container>
        </section>
    </div>
</template>
<script>
export default {
    mounted(){
        this.getProjects()
    }
}
</script>
<style>
#WorkPage header {
    height: 300px;
    position: relative;
    background-image: url('../assets/imgs/03.jpg');
    background-size: cover;
    background-position: center ;
} 
#WorkPage header img {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
} 

</style>
