<template>
  <div class="home">
    <header clss = "position-relative">
        <div class="content text-center">
          <h1 v-text = "about.title[getLang]"></h1>
          <p class = " lead mx-auto " v-text = "pageContent.header_body[getLang]"></p>
        </div>
        <div class="scrolldown-wrapper">
        <div class="scrolldown">
          <svg height="30" width="10">
            <circle class="scrolldown-p1" cx="5" cy="15" r="2" />
            <circle class="scrolldown-p2" cx="5" cy="15" r="2" />
          </svg>
        </div>
      </div>
    </header>
    <section id="about" class = "py-5 position-relative">
      
      <div class="over-lay position-absolute top-0 left-0 h-100 w-100"></div>
      <b-container>
      <div class="about-content">
        <b-row>
          <b-col sm="12" lg = "6">
            <div class="img-slider mx-auto">
              <img src="../assets/imgs/03.jpg" alt="">
              <img src="../assets/imgs/dot.png" class = "dot" width="100" alt="">
            </div>
          </b-col>
          <b-col sm="12" lg = "6" class = "align-self-center">
            <div class="about-content position-relative">
              <div class="title-section">
                <h2 v-text = "pageContent.about_title[getLang]"></h2>
                <span class = "underline d-block"></span>
              </div>
              <p class = "py-4" v-text = "about.description[getLang]"></p>
            
              <ul class = 'list-unstyled w-100'>
                <li class = "text-center py-3">
                  <h3>
                    {{about.childCount}}
                  </h3>
                  <span v-text = "getLang == 'ar'?'طفل متبنى':'Child'"></span>
                </li>
                <li class = "text-center py-3">
                  <h3>
                    {{about.projects}}
                  </h3>
                  <span v-text = "getLang == 'ar'?'حملة':'charity'"></span>
                </li>
                <li class = "text-center py-3">
                  <h3>
                    {{about.charges}}
                  </h3>
                  <span v-text = "getLang == 'ar'?'مبلغ مصروف':'Amount'"></span>
                </li>
                
              </ul>
            </div>
          </b-col>
        </b-row>
      </div>
      </b-container>
    </section>
    <section id="ourwork" class = "pt-5">
      <div class="title-section pt-5 text-center">
        <h2 v-text = "pageContent.work_title[getLang]"></h2>
        <span class = "underline d-block mx-auto"></span>
      </div>
      <b-container>
        <div class="work-content px-5 py-4">
          <work-card v-for = "project in projects" :key="project.id" :work = "project"></work-card>
        </div>
        <div class="section-controll text-center">
          <router-link to = "/work" class = "px-3 py-2 rounded" v-text = "getLang == 'en' ? 'View More' : 'عرض المزيد'"></router-link>
        </div>
      </b-container>
    </section>
    <section id="permissin" class = "py-5">
      <b-container>
        <b-row>
          <div class="title-section text-center">
            <h2 v-text = "pageContent.lic_title[getLang]"></h2>
            <span class = "underline d-block mx-auto"></span>
          </div>
          <div class="per-content py-3">
            <per-card v-for = "licens in licenses" :key="licens.id" :info = "licens"></per-card>
          </div>
          <div class="section-controll text-center py-2">
            <router-link to = "/" class = "px-3 py-2 rounded" v-text = "getLang == 'en' ? 'View More' : 'عرض المزيد'"> </router-link>
          </div>
        </b-row>
      </b-container>
    </section>
    <section id = "contact" class = "pt-5">
      <div class="map">
        <iframe class = "w-100 h-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1432.7620661882834!2d44.339475319230175!3d33.269478283122886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1559d59942cf1e45%3A0x7819226a63afbcb1!2z2YXYpNiz2LPYqSDYs9mF2Kcg2KjYutiv2KfYryDYp9mE2K7Zitix2YrYqSDZhNmE2KfYutin2KvYqSDZiCDYp9mE2KrZhtmF2YrYqQ!5e0!3m2!1sar!2siq!4v1653250831388!5m2!1sar!2siq" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <div class="contact-info bg-white py-5 px-5">
        <div class="title-section">
          <h2 v-text = "pageContent.contact_title[getLang]"></h2>
          <span class = "underline d-block"></span>
        </div>
        <ul class = "list-unstyled pt-4">
          <li class = "d-flex align-items-center mb-5">
            <span class = "icon">
              <i class="fa-solid fa-location-dot"></i>
            </span>
            <span class = "font-mer contact-value px-4">
              {{about.address}}
            </span>
          </li>
          <li class = "d-flex align-items-center mb-5">
            <span class = "icon">
              <i class="fa-solid fa-envelope"></i>
            </span>
            <span class = "contact-value px-4">
              {{about.email}}
            </span>
          </li>
          <li class = "d-flex align-items-center">
            <span class = "icon">
              <i class="fa-solid fa-phone"></i>
            </span>
            <span class = "contact-value px-4">
              {{about.phone}} 
            </span>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  components: {},
  data(){
    return{
      
    }
  },
  mounted(){
    this.getAbout()
    this.getProjects()
    this.getLicenses()
  }
}
</script>
