import $http from "@/plugins/axios.js"

export default {
    state:{
        about:{
            title:{
                en:'',
                ar:''
            },
            description:{
                en:'',
                ar:''
            },
            childCount:0,
            projects:0,
            charges:'',
            phone:'',
            address:'',
            email:'',
            images:[]
        }
    },
    getters:{
        about: state => state.about
    },
    actions:{
        async getAbout({commit}){
            const response = await $http.get(`/about`)
            commit("SET_ABOUT", response.data.data);
        },

        async updateAbout({commit} , data){
            await $http.post(`/about`,{"content":data})
            // commit('UPDATE_ABOUT',response.data)
            return commit
        }
    },
    mutations:{
        SET_ABOUT(state,data){
            state.about = JSON.parse(data)
        },
        UPDATE_ABOUT(state,data){
            state.siteSittings  = state.siteSittings.map(item => item = item.id == data.id ? data : item)
        },
        
    }
}