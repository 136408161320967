<template>
    <div class="my-navbar">
        <b-container>
            <b-navbar toggleable="lg" type="dark">

                
            <b-navbar-brand href="#">
                <img src="../../assets/imgs/logo.png" width="75" alt="">
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" class = "justify-content-between" is-nav>
            <b-navbar-nav>
                <b-nav-item v-for = "item in sidebarContent" :key = "item.id">
                    <router-link active-class="active" exact :to="item.link" v-text = "item.name[getLang]"></router-link>
                </b-nav-item>
            </b-navbar-nav>
            <ul class = "list-unstyled">
                <li>
                    <span class = "lang-toggle" @click="changeLang()" v-text = "getLang == 'en' ? 'الغة العربية' : 'اللغة الانكليزية'"></span>
                </li>
            </ul>
            </b-collapse>
            </b-navbar>
        </b-container>
  </div>
</template>

<style>
    
    .lang-toggle{
        text-decoration: underline;
        cursor: pointer;
    }
    .navbar-toggler-icon{
        background-color: var(--text-color);
    }
    
    .my-navbar{
        position: fixed;
        width: 100%;
        z-index: 999999;
        background: #fff;
    }
    
    .navbar-dark {
        padding-top: 20px;
        padding-bottom: 20px;
        transition: all .5s ease-in-out;
    }
    .navbar-dark .navbar-nav .nav-link a, .navbar-dark .navbar-nav .nav-link  a{
        color: var(--text-color) !important;
        text-decoration: none;
        transition: all .5 ease-in-out;
    }

    .navbar-dark .navbar-nav .nav-link a:hover{
        color: var(--main-color) !important;
    }

    .navbar-dark .navbar-nav .nav-link a.active {
        color: var(--main-color) !important;
    }

    .navbar-dark .navbar-nav .nav-link {
        color: var(--title-color) !important;
        font-weight: bold;
    }

</style>

<script>
export default {
    data(){
        return{
            sidebarContent:[
                {
                    id:1,
                    name:{
                        en:'Home',
                        ar:'ألرئيسية'
                    },
                    link:'/'
                },
                {
                    id:2,
                    name:{
                        en:'About',
                        ar:'عن الشركة'
                    },
                    link:'/'
                },
                {
                    id:3,
                    name:{
                        en:'Our Works',
                        ar:'أعمالنا'
                    },
                    link:'/work'
                },
                {
                    id:4,
                    name:{
                        en:'Licenses',
                        ar:'التراخص'
                    },
                    link:'/licenses'
                },
            ]
        }
    },
    mounted(){
        window.addEventListener('scroll',()=>{
            if(window.scrollY > 100) {
                document.querySelector('.navbar-dark').classList.add('active')
            }else{
                document.querySelector('.navbar-dark').classList.remove('active')
            }
        })
    }
}
</script>