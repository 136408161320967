import $http from "@/plugins/axios.js"

export default {
    state:{
        socials:[]
    },
    getters:{
        socials: state => state.socials
    },
    actions:{
        async getSocials({commit}){
            const response = await $http.get(`/contact`)
            var res = JSON.parse(response.data.data)
            
            res.map(item=>{
                item.name={
                    ar:item.name_ar,
                    en:item.name_en,
                }
            })
            commit("SET_SOCIAL", res);
        },

        async createSocial({commit},social){
            await $http.post('/contact',{"content":social})
            //commit('ADD_NEW_SOCIAL',response.data.obj)
            return commit
        },

        async updateSocial({commit} , social){
            await $http.post(`/contact`,{"content":social})
            return commit
            //commit('UPDATE_SOCIAL',response.data.obj)
        },
        async deleteSocial({commit} , id){
            const response = await $http.delete(`/SocialMedias/${id}`)
            commit('DELETE_SOCIAL',response.data.obj.id)
        }
    },
    mutations:{
        SET_SOCIAL(state,data){
            state.socials = data
        },
        ADD_NEW_SOCIAL(state,data){
            state.socials.push(data)
        },
        UPDATE_SOCIAL(state,data){
            state.socials  = state.socials.map(item => item = item.id == data.id ? data : item)
        },
        DELETE_SOCIAL(state,id){
            state.socials  = state.socials.filter(item => item.id !== id)
        },
        
    }
}