import axios from "axios";
import store from "@/store/index.js"

axios.interceptors.request.use(config => {
    config.baseURL = "https://api.sama-iq.info/api",
    config.headers.Authorization = `Base ${ localStorage.getItem('il_user_Info') !== null ? JSON.parse(localStorage.getItem('il_user_Info')).auth : ''}`
    config.method == "get" ? store.state.getLoader = true : store.state.postLoader = true
    config.headers = {
        'Access-Control-Allow-Origin':'*',
        'Authorization': `Bearer ${ localStorage.getItem('sama-info') !== null ? JSON.parse(localStorage.getItem('sama-info')).token : ''}`,
        'Accept':'application/json'
    }
    return config;

})

axios.interceptors.response.use(response => {
    response.config.method == "get" ? store.state.getLoader = false : store.state.postLoader = false
    return response
}, (error)=>{
    store.state.getLoader = false 
    store.state.postLoader = false 

    return Promise.reject(error);

}
)

export default axios ; 