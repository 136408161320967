<template>
    <div id="work_details">
        <header :style="{ backgroundImage: 'url(' + oneItem.cover + ')' }"></header>
        <section class = "details-cont py-3">
             <b-container>
                 <div class="work_title">
                    <h3 class = "font-mer mb-0" v-text = "oneItem.title[getLang]">
                    </h3>
                    <span class = "date">
                        {{oneItem.date}}
                    </span>
                </div>
                <p class = "py-3" v-text = "oneItem.body[getLang]">
                </p>
                
                <div class="imgs-content">
                    <b-row>
                        <b-col cols='3' v-for = "item in oneItem.imgs" :key="item">
                            <img class = "img-fluid mb-2" :src="item" alt=""   height="200">
                        </b-col>
                    </b-row>
                </div>

            </b-container>
        </section>
    </div>
</template>

<script>

export default {
    props:['id'],
    mounted(){
        this.getProjectOne(this.id)
    }
}

</script>

<style>
#work_details {
    min-height: 100vh;
}
#work_details header {
    height: 400px;
    background: url("../assets/imgs/03.jpg");
    background-size: cover;
    background-position: center ;
}
</style>