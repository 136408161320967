<template>
    <div id="PermPage">
        <section class = "py-5">
            <div class="title-section py-5 text-center">
                <h2 v-text = "pageContent.lic_title[getLang]"></h2>
                <span class = "underline d-block mx-auto"></span>
            </div>
            <b-container>    
                <div class="work-content py-5">
                    <per-card v-for = "licens in licenses" :key="licens.id" :info = "licens"></per-card>
                </div>
            </b-container>
        </section>
    </div>
</template>
<script>
export default {
    mounted(){
        this.getLicenses()
    }
}
</script>
<style>
#PermPage header {
    height: 300px;
    position: relative;
    background-image: url('../assets/imgs/03.jpg');
    background-size: cover;
    background-position: center ;
} 
#PermPage header img {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
} 

#PermPage .work-content{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
}

</style>
