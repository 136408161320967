import $http from "@/plugins/axios.js"

export default {
    state:{
        projects:[],
        oneItem:{}
    },
    getters:{
        projects: state => state.projects,
        oneItem: state => state.oneItem,
        
    },
    actions:{
        async getProjects({commit}){
            const response = await $http.get(`/our-works`)
            response.data.data.data.map(item=>{
                item.content = JSON.parse(item.content)
            })
            response.data.data.data.map(el=>{
                el.content.title = {
                    en:el.content.title_en,
                    ar:el.content.title_ar  
                }
                el.content.body = {
                    en:el.content.body_en,
                    ar:el.content.body_ar  
                }
            })
            commit("SET_PROJECTS", response.data.data.data);
        },
        async getProjectOne({commit},id){
            const response = await $http.get(`/our-works/${id}`)
            var data = JSON.parse(response.data.data.content)
            data.title = {
                ar:data.title_ar,
                en:data.title_en,
            }
            data.body = {
                ar:data.body_ar,
                en:data.body_en,
            }
            commit("SET_PROJECT", data);
        },

        async createProject({commit},project){
            await $http.post('/our-works',project)
            return commit
            // commit('ADD_NEW_PROJECT',response.data.data)
        },

        async updateProject({commit} , project){
            await $http.put(`/our-works/${project.id}`,{"content":JSON.stringify(project)})
            return commit
        },
        async deleteProject({commit} , id){
            await $http.delete(`/our-works/${id}`)
            return commit
        }
    },
    mutations:{
        SET_PROJECTS(state,data){
            state.projects = data
        },
        SET_PROJECT(state,data){
            state.oneItem = data
        },
        ADD_NEW_PROJECT(state,data){
            state.projects.push(JSON.parse(data))
        },
        UPDATE_PROJECT(state,data){
            state.projects  = state.projects.map(item => item = item.id == data.id ? data : item)
        },
        DELETE_PROJECT(state,id){
            state.projects  = state.projects.filter(item => item.id !== id)
        },
        
    }
}