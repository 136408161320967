<template>
    <div id="work_card" class = "bg-white">
        <div class="img_card">
            <img :src="work.content.cover" alt="">
        </div>
        <div class="card-content px-3 py-4">
            <h3 class = "font-mer mb-0" v-text = "work.content.title[getLang]">
            </h3>
            <span class = "date">
                {{work.content.date}}
            </span>
            <p class = "py-3 pt-4 mb-0" v-text="splitText(work.content.body[getLang],150)"></p>
            <div class="card-controll text-center pt-3 border-top">
                <router-link :to = "'/work_details/'+work.id" v-text = "getLang == 'en' ? 'Read More' : 'أقراء المزيد'">
                    
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:['work']
}
</script>
<style>
 #work_card img {
     height: 200px;
     width: 100%;
     object-fit: cover;

 }
 #work_card h3 {
     color: var(--title-color);
     transition: all .5s ease-in-out;
 }
 #work_card .date {
     font-weight: bold;
     font-size: 15px;
     color: var(--text-color);
 }
 #work_card p {
     color: var(--text-color);
 }
 #work_card a {
    color: var(--text-color) !important;
    transition: all .5s;
 }
 #work_card a:hover {
     color: var(--main-color) !important;
 }

 #work_card:hover h3 {
    color: var(--main-color);
 }
</style>