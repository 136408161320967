import Vue from 'vue'
import Vuex from 'vuex'

import about from "./modules/about.js"
import Projects from "./modules/Projects.js"
import Licenses from "./modules/Licenses.js"
import SocialMedia from "./modules/SocialMedia.js"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang: localStorage.getItem('sama-lang') || 'en',
    getLoader:false,
    postLoader:false,
  },
  getters: {
    getLang:state => state.lang
  },
  mutations: {
    changeLang(state){
        if(state.lang == 'en'){
          state.lang = 'ar'
          localStorage.setItem('sama-lang','ar')
        }else{
          state.lang = 'en'
          localStorage.setItem('sama-lang','en')
        }
    }
  },
  actions: {
  },
  modules: {
    about,
    Projects,
    SocialMedia,
    Licenses
  }
})
