<template>
  <div id="app" :class = "[getLang == 'ar'? 'rtl' : 'ltr']">
    <navbar />
    <router-view/>
    <div class="footer py-2 text-center">
      <p>
        All Copyright resaved 
      </p>
      <ul class = "list-unstyled d-flex algin-items-center justify-content-center mb-0">
        <li v-for = "social in socials" :key="social.name_ar" class = "mx-2">
          <a :href="social.url" target="_blank" v-text="social.name[getLang]">
          </a>
        </li>
        
      </ul>
    </div>
  </div>
</template>
<script>

import navbar from "./components/global/_navbar.vue"

export default {
  components:{
    navbar
  },
  mounted(){
    this.getSocials()
  }
}

</script>