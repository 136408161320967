import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import workCard from './components/global/_workCard.vue'
import perCard from './components/global/_permCard.vue'
import { BootstrapVue } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/style/index.css'

import { mapGetters , mapMutations , mapActions } from 'vuex'

var mixin = {
  data(){
    return{
      pageContent:{
        header_title:{
          en:'Sama Baghdad Chantable Foundation Reliet & Development',
          ar:'مؤسسة سما بغداد الخيرية للاغاثة و التنمية'
        },
        header_body:{
          en:'We are campaigning to help people who need help, help and contribute to building a decent life for families with limited incomes.',
          ar:'مؤسسة سمى بغداد الخيرية للاغاثة و التطوير نحن نقوم بعمل حملات لمساعدة الاشخاص الذين يحتاجون للمساعدة و العون و المساهمه في بناء حياة كريمة للعوائل ذات الدخلا لمحدود'
        },
        about_title:{
          en:'About Us',
          ar:'عــن الـمؤسسة'
        },
        work_title:{
          en:'Our Work',
          ar:'أعمالنا'
        },
        lic_title:{
          en:'licenses',
          ar:'التراخيص'
        },
        contact_title:{
          en:'Contact with us Via',
          ar:'تواصل معنا عبر'
        },
      }
    }
  },
  computed:{
    ...mapGetters(['']),
        ...mapGetters([
            'getLang',          
            'getLoader',
            'user',
            'about',
            'licenses',
            'projects',
            'oneItem',
            'getProject',
            'socials',
            'userInfo'
        ]),
  },
  methods:{
    ...mapMutations(['changeLang']),
    ...mapActions([
      'login',

      'getUser',
      'updateUser',

      'getAbout',
      'updateAbout',

      'getProjects',
      'getProjectOne',
      'createProject',
      'updateProject',
      'deleteProject',

      'getLicenses',
      'createLicens',
      'updateLicens',
      'deleteLicens',

      'getSocials',
      'createSocial',
      'updateSocial',
      'deleteSocial',
  ]),
  splitText(text,length){
        if(text !== null && text.length > length ){
            return text.slice(0,length) + '...'
        }else{
            return text
        }
    }
  }
}

Vue.mixin(mixin)


// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.component('work-card', workCard)
Vue.component('per-card', perCard)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
