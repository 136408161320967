<template>
    <div id="perm_card" class = "bg-white">
        <div class="perm_card">
            <a :href="info.content.cover" target="_blanck">
                <img :src="info.content.cover" alt="">
            </a>
        </div>
        <div class="perm-content px-3 py-4">
            <h5 class = "font-mer mb-0" v-text="info.content.title[getLang]"></h5>
        </div>
    </div>
</template>
<script>
export default {
    props:['info']
}
</script>
<style>
 #perm_card img {
     height: 500px;
     width: 100%;
     object-fit: cover;
 }
 #perm_card h3 {
     color: var(--title-color);
     transition: all .5s ease-in-out;
 }

 #perm_card:hover h3 {
    color: var(--main-color);
 }
</style>